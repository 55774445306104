var sdb = (function($) {
    return {
        initData: {},
        initialize: function() {
            var initDropdown = $('#vendor-dropdown'),
                initSource = initDropdown.data('source');

            if (initSource) {
                this.requestData(initDropdown, initSource);
            }

            this.initData['tableHeading'] = $('#product-list').find('tr').first();

        },
        requestData: function (sourceDropdown, sourceUrl) {
            var that = this;

            $.ajax({
                method: 'GET',
                dataType: "json",
                url: sourceUrl
            }).done(function(data) {
                that.fillTarget(sourceDropdown, data);
            });
        },
        fillTable: function(data) {

           var productList = $('#product-list');

           if (productList[0]) {

               var noResultLabel = productList.data('noresult');

               productList.find('tr').remove();

               this.initData['tableHeading']
                   .appendTo(productList);

               if (data.length > 0) {
                   for (var i = 0; i < data.length; i++) {
                       var tableData = data[i];

                       $('<tr><td>' + tableData.material + '</td><td>' + tableData.validFrom + '</td><td>' + tableData.id + '</td><td>' + tableData.productType + '</td><td>' + tableData.ceMarking + '</td><td>' + tableData.downloadUrl + '</td></tr>')
                           .appendTo(productList)
                   }
               } else {
                   $('<tr><td colspan="6">' + noResultLabel + '</td>')
                       .appendTo(productList)
               }

               productList.removeClass('hide');
           }
        },
        fillDropdown: function(sourceDropdown, data) {

            var defaultLabel = sourceDropdown.data('placeholder');

            sourceDropdown.html('');

            $('<option>' + defaultLabel + '</option>')
                .appendTo(sourceDropdown);

            for (var i = 0; i < data.length; i++) {
                var optionElement = data[i];

                $('<option value="' + optionElement.id + '">' + optionElement.name + '</option>')
                    .appendTo(sourceDropdown);
            }

            sourceDropdown.parents('.select-group').removeClass('hide');
        },
        fillTarget: function (sourceDropdown, data) {

            if (sourceDropdown.prop("tagName").toLowerCase() === 'select') {

                this.fillDropdown(sourceDropdown, data);

            } else {
                this.fillTable(data);
            }

        },
        setInitData: function (name, value) {
            this.initData[name] = value;
            this.setTableSource();
            this.setDropdownSources();
        },
        setTableSource: function() {
            var source = $('#product-list').data('orig');

            for (var prop in this.initData) {
                if (this.initData.hasOwnProperty(prop)) {
                    source = source.replace('__' + prop + '__', this.initData[prop]);
                }
            }

            $('#product-list').data('source', source).attr('data-source', source);
        },
        setDropdownSources: function() {
            var that = this;

            $('#product-list').addClass('hide');

            $('body .select-form select').each(function() {

                var source = $(this).data('orig');

                for (var prop in that.initData) {

                    if (that.initData.hasOwnProperty(prop)) {
                        source = source.replace('__' + prop + '__', that.initData[prop]);
                    }
                }

                $(this).data('source', source).attr('data-source', source);
            });
        },
        updateTarget: function(sourceDropdown) {
            var target = sourceDropdown.data('target'),
                targetElement = $('#' + target);

            if (targetElement[0]) {
                var name = sourceDropdown.attr('name');

                this.setInitData(name, sourceDropdown.val());

                var source = targetElement.data('source');

                this.requestData(targetElement, source);

                if (name === 'supplierid') {
                    $('#product-dropdown').parents('.select-group').addClass('hide');
                }
            }
        },
        attachEventHandlers: function() {
            var that = this;

            $('body').on('change', '.select-form select', function(e) {
                that.updateTarget($(this));
            })
        }
    };
})(jQuery);

$(document).ready(
    function() {
        sdb.initialize();
        sdb.attachEventHandlers();
    }
);
